import React, {useEffect} from 'react'

const Bridge = ({navigate}) => {
  useEffect(()=>{
    navigate('/');
  },[])
  return null;
}

export default Bridge;
